<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" />
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" />
        <filter-area class="mt-2" v-model:value="area" v-model:provinsi="provinsi" v-model:region="region" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <filter-kabupaten-kota class="mr-2 mt-2" v-model:value="kabupaten" v-model:area="area" />
        <filter-brand class=" mt-2" v-model:value="brand" v-model:brand="brand" />
        <a-month-picker
          class="ml-2 mt-2"
          v-model:value="start_date"
          placeholder="Dari Bulan"
          :disabled-date="disabledStartDate"
        />
        <a-month-picker
          v-model:value="end_date"
          placeholder="Sampai Bulan"
          class="ml-2 mr-2"
          :disabled-date="disabledEndDate"
        />
        <a-button
          class=" mr-2 mt-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
        <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <e-chart :title="title" vif="!isFetching" :response="response" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  reactive,
  inject,
  onMounted,
  computed,
  nextTick,
  toRefs,
  onBeforeMount,
  provide,
  watch,
} from 'vue'
import Loading from '@/components/Loading'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
// import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrand from '@/components/filter/FilterBrand'
// import FilterProducts from '@/components/filter/FilterProduct'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import { useDisabledDate } from '@/composables/DisabledDate'
import qs from 'qs'
import moment from 'moment'

const acolumns = [
  {
    title: 'bulan',
    dataIndex: 'bulan',
  },
  {
    title: 'Kode Produk',
    dataIndex: 'product_code',
  },
  {
    title: 'Produk',
    dataIndex: 'product_name',
  },
  {
    title: 'Volume',
    dataIndex: 'volume_ton',
  },
  {
    title: 'Brand',
    dataIndex: 'brand_name',
  },
  {
    title: 'Region',
    dataIndex: 'region',
  },
  {
    title: 'Provinsi',
    dataIndex: 'provinsi',
  },
  {
    title: 'Area',
    dataIndex: 'area',
  },
  {
    title: 'Kabupaten / Kota',
    dataIndex: 'kabupaten',
  },
  {
    title: 'Harga Jual Rata-rata',
    dataIndex: 'avg_price',
  },
  {
    title: 'Harga Jual Minimal',
    dataIndex: 'min_price',
  },
  {
    title: 'Harga Jual Maksimal',
    dataIndex: 'max_price',
  },
]

export default defineComponent({
  name: 'MFilter',
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    // FilterDistributor,
    FilterBrand,
    // FilterProducts,
    FilterKabupatenKota,
    EChart: defineAsyncComponent({
      loader: () => import('./EChart.vue' /* webpackChunkName: "eChart" */),
      loadingComponent: Loading,
    }),
  },
  setup() {
    const data = ref([])
    const searchText = ref('')
    const searchInput = ref(null)
    const current1 = ref(1)
    const q = ref('')

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const distributor = ref([])
    const area = ref([])
    const brand = ref([])
    const errorMessage = ref(null)
    const {
      start_date,
      end_date,
      disabledStartDate,
      firstDayMonth,
      lastDayMonth,
      disabledEndDate,
      startDate,
      endDate,
    } = useDisabledDate()
    const columns = ref([])
    const response = ref()

    columns.value = acolumns

    const params = ref({})
    const fetchData = () => {
      const _params = {
        // page: page.value,
        // 'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        //  product: products.value,
        brand: brand.value,
        distributor: distributor.value,
        start_date: firstDayMonth.value,
        end_date: lastDayMonth.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report/grafik-brand', {
          params: _params,
        })
        .then(({ data }) => {
          response.value = data
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/report/performance-brand', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `laporan-transaksi-per-brand_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const state = reactive({
      loading: false,
      isFetching: false,
      isDownloading: false,
    })
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const title = computed(() => {
      return 'Performance Brand'
    })

    return {
      q,
      searchText,
      searchInput,
      data,
      current1,
      region,
      provinsi,
      kabupaten,
      area,
      brand,
      state,
      fetchXlsx,
      distributor,
      fetchData,
      ...toRefs(state),
      start_date,
      end_date,
      startDate,
      endDate,
      search,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
      columns,
      response,
      title,
    }
  },
})
</script>
